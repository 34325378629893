import Container from 'react-bootstrap/Container'

const NotFound = () => {
    return (
        <Container>
            <h1>Страница не найдена</h1>
        </Container>
    )
}

export default NotFound